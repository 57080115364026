.offer-card {
    .offer-image-container {
        display: flex;
        flex: 1;
        justify-content: center;
        height: 220px;
    }

    .offer-image {
        max-width: 200px !important;
        max-height: 194px !important;
        transition: transform 0.3s ease;

        &:hover {
            transform: scale(1.02);
        }
    }

    .offer-badge {
        left: 2rem;
        background: #e0e0f9;
        color: #666666;
        height: 32px;
        font-size: 14px;
        font-weight: 500;
        padding: 8px 16px;
        border-radius: 16px;
    }

}

.best-offer-card {
    border: 4px solid #20D489;
    position: relative;
    overflow: hidden;

    &::before {
        content: 'Melhor oferta';
        position: absolute;
        top: 20px;
        right: -60px;
        background-color: #20D489;
        color: #ffffff;
        padding: 5px 60px;
        transform: rotate(45deg);
        font-size: 12px;
        font-weight: bold;
        z-index: 1;
    }
}

.best-offer {
    background-color: #20D489;
    position: absolute;
    color: #ffffff;
    left: 10px;
    top: -15px;
    width: 60%;
    height: 30px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    box-shadow: 0 2px 8px rgba(32, 212, 137, 0.3);
}