//
// Header
//

// Desktop mode
@include media-breakpoint-up(lg) {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 2;
    height: get($header-config, default, height, desktop);

    // Fixed header & sticky header modes
    .header-fixed & {
      position: fixed;
      top: 0;
      right: 0;
      left: get($aside-config, width, desktop);
      z-index: get($header-config, fixed, z-index);
      box-shadow: get($header-config, fixed, box-shadow);
      height: get($header-config, fixed, height, desktop);
      padding: 0;
    }

    // Header menu
    .header-menu {
      display: flex;
    }
  }
}

.open-menu-header-fixed{
  position: fixed;
  top: 0;
  right: 0;
  left: get($open-aside-config, width, desktop) !important;
  z-index: get($header-config, fixed, z-index);
  box-shadow: get($header-config, fixed, box-shadow);
  height: get($header-config, fixed, height, desktop);
  padding: 0;
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: get($header-config, default, height, tablet-and-mobile);
    position: relative;
    z-index: 3;

    // Fixed header and header sticky modes
    .header-tablet-and-mobile-fixed & {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: get($header-config, fixed, z-index);
      height: get($header-config, fixed, height, tablet-and-mobile);
      min-height: get($header-config, fixed, height, tablet-and-mobile);
      box-shadow: get($header-config, fixed, box-shadow);
    }
    // Header menu
    .header-menu {
      display: none;
      z-index: get($header-config, fixed, z-index) - 1;
    }

    // Page title
    .page-title {
      display: none !important;
    }
  }
}
