//
// CircularLoading
//

.circularLoading {
  animation: is-rotating 1s infinite;
  border: 4px solid #e5e5e5;
  border-radius: 50%;
  border-top-color: #4154BF;
  height: 26px;
  width: 26px;
}