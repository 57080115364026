.select-container {
  overflow-y: visible;
  position: relative;

  .custom-select {
    display: flex;
    align-items: center;
    border: 1px solid #CED4DA;

    .icon-box {
      width: 46px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #E9ECEF;
    }

    .selected-box {
      width: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      background-color: #FFFFFF;
      padding: 6px 12px;

      span {
        width: calc(100% - 24px);
        color: #595959;
        font-size: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    
        &.empty-value{
          color: #808080;
        }
      }

      .down-arrow {
        width: 20px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #808080;
        transition: all 400ms;

        &.rotate {
          transform: rotate(180deg);
        }
      }
    }
  }

  .click-outside {
    width: 100vw;
    height: 100vh;
    display: none;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 100;

    &.open-dropdown {
      display: block;
    }
  }

  .dropdown {
    width: calc(100% - 46px);
    max-height: 0px;
    position: absolute;
    top: 0px;
    left: 46px;
    z-index: 101;
    background-color: #FFF;
    overflow-y: scroll;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: all 400ms;

    &.is-open {
      max-height: 200px;
      border: 1px solid #595959;
    }

    span {
      display: block;
      text-align: center;
      padding: 5px 12px;
      cursor: pointer;

      &:hover {
        background-color: #EFEFEF;
      }
    }
  }
}