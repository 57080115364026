//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Theme colors
// Primary
$primary: #4154BF;
$primary-active: #2D3A86;
$primary-light: #ffffff;    
$primary-inverse: #ffffff;

// Success
$success: #40BF60;
$success-active: #2D8643;
$success-light: #B3E6C0;
$success-inverse: #ffffff;

// Info
$info: #808080;
$info-active: #595959;
$info-light: #BFBFBF;
$info-inverse: #ffffff;

// Danger
$danger: #E6196E;
$danger-active: #A1124D;
$danger-light: #F5A3C5;
$danger-inverse: #ffffff;

// Warning
$warning: #E66F1A;
$warning-active: #A14D12;
$warning-light: #F5C5A3;
$warning-inverse: #ffffff;

// Filter-border
$filter-border: #e4e6ef; 
$filter-border-active: #cccedf;
$filter-border-light: #f2f2fc; 
$filter-border-inverse: #3f4254;

// Open
$open: #F5C5A3;

//In_progress
$in_progress: #F5C15C;

//Closed
$closed: #262626;

//Waiting_stock_share_delivery
$waiting_stock_share_delivery: #F5A3C5;
$waiting_stock_share_delivery-active: #A1124D;

//Waiting_approve
$waiting_approve: #B3BAE5;

//accepted
$accepted: #B3E6C0;

//rejected
$rejected: #F55C5C;

//Dark
$dark: #262626;

//Filter color
$filter: #f5f8fa;
$filter-text: #5e6278;
$filter-border: #e4e6ef; 

$light-gray: #eff2f5;
$medium-gray: #3f4254;

//Customer-level:
$bronze: #AF5D21;
$silver: #6E727B;
$gold: #DCA745;
$diamond: #1B1B1F;

$snow: #F2F2F2;
$vulcan: #F55C5C;
$dark-vulcan: #bd0404ea;
$sand: #F5C15C;
$dark-sand: #EBA822;
$ocean: #5C73F5;
$navy: #4154BF;
$navy-20: #1A214C;
$navy-35: #2D3A86;
$navy-65: #7987D2;
$navy-80: #B3BAE5;
$naval: #E6196E;
$naval-20: #5C0A2C;
$naval-35: #A1124D;
$naval-65: #EE5E9A;
$naval-80: #F5A3C5;
$army: #40BF60;
$army-20: #194D27;
$army-35: #2D8643;
$army-65: #79D290;
$army-80: #B3E6C0;
$empty-quarter: #E66F1A;
$empty-quarter-20: #5C2C0A;
$empty-quarter-35: #A14D12;
$empty-quarter-65: #ED9A5E;
$empty-quarter-80: #F5C5A3;
$night-camo: #808080;
$night-camo-20: #262626;
$night-camo-35: #595959;
$night-camo-65: #A6A6A6;
$night-camo-80: #BFBFBF;
$neutral-4: #0E0E11;

//Yellow scale:
$yellow-100: #fff3cd;
$yellow-600: #CC8F14;
$yellow-900: #332701;
$orange-100: #FFE8DE;
$orange-700: #CC7814;

//Blue-scale:
$cyan-100: #cff4fc;
$cyan-900: #032830;
$blue-100: #cfe2ff;
$blue-900: #031633;

//Red-scale:
$red-100: #f8d7da;
$red-500: #F64E60;
$red-900: #2c0b0e;
$purple-100: #e2d9f3;
$purple-900: #160d27;

//Green scale:
$green-100: #d1e7dd;
$green-900: #051b11;

//Gray scale:
$gray-200: #e9ecef;
$gray-700: #39393C;
$gray-900: #212529;
