.quote-list-item-container {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.quote-list-item-container:hover{
  background-color: #f5f5f5c7;
}

.quote-list-item-container:active {
  border: #7987D2
}