.search-loader {
  .carousel {
    display: block;
    width: 100%;
    height: 70px;
    .carousel-inner {
      height: 100%;
      .carousel-caption {
        position: unset;
      }
    }
  }
}