.quote-operation {
  @include media-breakpoint-up(lg) {
    .quote-list-container {
      top: get($header-config, fixed, height, desktop) !important;
      left: get($aside-config, width, desktop) !important;
    }
  }
  
  @include media-breakpoint-down(lg) {
    .quote-list-container {
      top: get($header-config, fixed, height, tablet-and-mobile) !important;
      left: get($aside-config, width, tablet-and-mobile) !important;
    }
  }
}