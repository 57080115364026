//
// SimpleTable
//

.simple-table {
  td, th {
    padding: 5px 15px;
    border-top: solid 1px #D7D7D7;
    text-align: center;
  }

  .thead-tr {
    background-color: #4154BF;
    color: #fff;
  }

  .tbody-td {
    .green {
      color: #C10000;
    }

    .red {
      color: #4DA032;
    }
  }
}


