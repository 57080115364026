//
// Theme style
//

// Mode
$mode: default;

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import 'layout/layout';
@import './core/layout/docs/layout';
@import 'react-h5-audio-player/lib/styles.css';
