//
// Transform
//

.transform-90 {
  transform: rotate(90deg)!important;
  transform-origin: right top !important;
}

.transform-180 {
  transform: rotate(180deg) !important; 
  transform-origin: right top !important;
}


.transform-270 {
  transform: rotate(270deg) !important;
  transform-origin: left top !important;
}

