//
// InformationConfirmationModal
//

.information-confirmation-modal-background {
  width: 100%;
  height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.information-confirmation-modal {
  width: 50%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  background-color: #fff; 
  padding: 40px;
  font-size: 16px;

  .buttons-box {
    display: flex;
    justify-content: end;
    gap: 20px;
  }

  & button {
    width: 180px;
    height: 50px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    color: #fff;
  }

  .confirm-button {
    background-color: rgb(45, 64, 173);
  }

  .confirm-button:hover {
    background-color: #273580;
  }

  .cancel-button {
    background-color: rgb(214, 6, 92);
  }

  .cancel-button:hover {
    background-color: rgb(172, 4, 74);
  }
}

.information-confirmation-modal-head {
  background-color: rgb(45, 64, 173);
  border-radius: 10px 10px 0 0;
  padding: 20px;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
}

.information-confirmation-modal-body {
  background-color: #fff;
  padding: 20px ;
  color: #000;
  border-radius: 0 0 10px 10px
}

.confirm-button {
  width: 300px;
  padding: 15px 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  border-radius: 10px;
  background-color: #4154BF;
  cursor: pointer;

  font-size: 16px;
  color: #fff;
}

.confirm-button:hover {
  background-color: #273580;
}

.flex_modal {
  background-color: #fff;
  border-radius: 10px;
  height: 400px;
  width: 50%;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .load {
    animation: is-rotating 1s infinite;
    border: 6px solid #e5e5e5;
    border-radius: 50%;
    border-top-color: #4154BF;
    height: 100px;
    width: 100px;
  }

  .success {
    height: 100px;
    background-color: #3AC01F;
    border-radius: 100px;
    color: pink;
  }

  .failure {
    height: 100px;
    background-color: red;
    border-radius: 100px;
  }

  span {
    font-size: 24px;
    font-weight: 500;
    margin-top: 20px;
  }
  
  @keyframes is-rotating {
    to {
      transform: rotate(1turn);
    }
  }
}

.flex-box-field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}