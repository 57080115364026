.offer-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 310px));
    grid-gap: 20px 20px;
  
    .card {
      &:hover {
        box-shadow: 0 0 2rem 0 rgba(166, 166, 166, 0.5);
      }

      .card-header {
        justify-content: center;
        padding: 2.25rem 0 0;
        border-bottom: 0;

        img {
          max-width: 100%;
          max-height: 100%;
          height: auto;
        }
      }
      .card-body {
        padding-top: 0;
        cursor: pointer;
      }

      .card-footer {
        border-top: none;
      }
    }
  }