.highlight{
    color:$night-camo-20;
    font-weight: 400;
    cursor: default;
}

.highlight:hover{
    font-weight:600;
}

.link{
    color: $night-camo;
}

.link:hover{
    color: $primary;
    text-decoration: underline;
    cursor: pointer;
}

.disabledState{
    background-color: transparent !important;
}

.disabledState:disabled{
    background-color: #eff2f5 !important;
}

.sensitive-link{
    color: $vulcan;
}

.sensitive-link:hover{
    color: $dark-vulcan;
    text-decoration: underline;
    cursor: pointer;
}

.zIndex-0{
    z-index: 0 !important;
}