//
// PaginationFooter
//

.pagination-footer {
  width: 100%;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  gap: 10px;
}

.arrow {
  cursor: pointer;
}
